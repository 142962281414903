import { waitAuth } from "~/utils/middleware";

import { log } from "~/composables/log";
import { waitForPB } from "~/composables/pb";
import { hasAuthorization, RoleRules } from "~/composables/team";

export default defineNuxtRouteMiddleware(async () => {
	await waitAuth();
	await waitForPB();

	if (!hasAuthorization(RoleRules.ObjectStorage_Identifier)) {
		log("❌ Cannot access object storage identifiers");
		return navigateTo("/");
	}

	log("✅ Can access object storage identifiers");
});
